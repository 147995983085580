import { IWizardTabComponent, IWizardTabsContainerComponent } from 'src/app/model/interfaces/wizard.interface';
import {
  O365MappingFieldsData,
  StaticFieldsData
} from 'src/app/modules/o365/model/interfaces/o365-preferences.interface';

/**
 * Wizard tab information
 */
export const PREFERENCES_WIZARD_TITLE: IWizardTabsContainerComponent = {
  translate: 'integration_preferences'
};

/**
 * Main steps wizard to crete o365 preferences
 */
export const PREFERENCES_WIZARD_STEPS: IWizardTabComponent[] = [
  {
    isFirstTab: true,
    open: true,
    optional: false,
    stepNo: 1,
    tabTitle: 'office_365_user_import'
  },
  {
    optional: false,
    stepNo: 2,
    tabTitle: 'o365-user-data-update'
  },
  {
    optional: false,
    stepNo: 3,
    tabTitle: 'o365-24-hour-sync'
  },
  {
    optional: false,
    stepNo: 4,
    tabTitle: 'empty-o365-data-fields'
  },
  {
    optional: false,
    stepNo: 5,
    tabTitle: 'o365_fulltime_sync_hl'
  },
  {
    optional: false,
    stepNo: 6,
    tabTitle: 'o365_employee_pictures_hl'
  },
  {
    completed: true,
    optional: true,
    stepNo: 7,
    tabTitle: 'user-data-mapping'
  },
  {
    completeFollowingText: 'complete-following-steps-to-save-preferences',
    isLastStep: true,
    stepNo: 8
  }
];

/**
 * O365 preview mapping headers
 */
export const O365_HEADERS = ['o365-data-fields', 'mailtastic-data-fields-only', 'mailtastic-user-example'];

/**
 * List of o365 data fields for adjust mapping on left side
 */
export const O365_FIELDS_DATA: StaticFieldsData[] = [
  {
    example: 'mail-example',
    isMapped: false,
    key: 'mail',
    translateKey: 'o365-username'
  },
  {
    example: 'first-name-example',
    isMapped: false,
    key: 'givenName',
    translateKey: 'first-name'
  },
  {
    example: 'last-name-example',
    isMapped: false,
    key: 'surname',
    translateKey: 'last-name'
  },
  {
    example: 'user-principal-name-example',
    isMapped: false,
    key: 'userPrincipalName',
    translateKey: 'user-principal-name'
  },
  {
    example: 'job-title-example',
    isMapped: false,
    key: 'jobTitle',
    translateKey: 'job-title'
  },
  {
    example: 'department-example',
    isMapped: false,
    key: 'department',
    translateKey: 'Abteilung'
  },
  {
    example: 'city-example',
    isMapped: false,
    key: 'officeLocation',
    translateKey: 'office-location'
  },
  {
    example: 'office-phones-example',
    isMapped: false,
    key: 'businessPhones',
    translateKey: 'office-phones'
  },
  {
    example: 'business-phones-example',
    isMapped: false,
    key: 'mobilePhone',
    translateKey: 'Mobiltelefon'
  },
  {
    example: 'business-phones-example',
    isMapped: false,
    key: 'faxNumber',
    translateKey: 'fax-number'
  },
  {
    example: 'street-address-example',
    isMapped: false,
    key: 'streetAddress',
    translateKey: 'street-address'
  },
  {
    example: 'city-example',
    isMapped: false,
    key: 'city',
    translateKey: 'Ort'
  },
  {
    example: 'state-example',
    isMapped: false,
    key: 'state',
    translateKey: 'state-province'
  },
  {
    example: 'postal-code-example',
    isMapped: false,
    key: 'postalCode',
    translateKey: 'postal-code-zip'
  },
  {
    example: 'country-example',
    isMapped: false,
    key: 'country',
    translateKey: 'ma_land'
  },
  {
    example: 'company-name-example',
    isMapped: false,
    key: 'companyName',
    translateKey: 'ma_firma'
  },
  {
    example: 'id-example',
    isMapped: false,
    key: 'id',
    translateKey: 'id'
  },
  {
    example: 'user-type-example',
    isMapped: false,
    key: 'userType',
    translateKey: 'user-type'
  },
  {
    example: 'employee-type-example',
    isMapped: false,
    key: 'employeeType',
    translateKey: 'employee-type'
  },
  {
    example: 'boolean-example',
    isMapped: false,
    key: 'accountEnabled',
    translateKey: 'account-enabled'
  },
  {
    example: 'branch-name-example',
    isMapped: false,
    key: 'branchName',
    translateKey: 'placeholder_branche1_1'
  },
  {
    example: 'branch-street-number-example',
    isMapped: false,
    key: 'branchStreetNumber',
    translateKey: 'placeholder_branche1_2'
  },
  {
    example: 'branch-zipcode-city-example',
    isMapped: false,
    key: 'branchZipCodeCity',
    translateKey: 'placeholder_branche1_3'
  },
  {
    example: 'branch2-street-number-example',
    isMapped: false,
    key: 'branchName2',
    translateKey: 'placeholder_branche2_1'
  },
  {
    example: 'branch2-zipcode-city-example',
    isMapped: false,
    key: 'branchStreetNumber2',
    translateKey: 'placeholder_branche2_2'
  },
  {
    example: 'branch2-zipcode-city-example',
    isMapped: false,
    key: 'branchZipCodeCity2',
    translateKey: 'placeholder_branche2_3'
  },
  {
    example: 'extension-attribute',
    index: 1,
    isMapped: false,
    key: 'extensionAttribute1',
    translateKey: 'extension-attribute'
  },
  {
    example: 'extension-attribute',
    index: 2,
    isMapped: false,
    key: 'extensionAttribute2',
    translateKey: 'extension-attribute'
  },
  {
    example: 'extension-attribute',
    index: 3,
    isMapped: false,
    key: 'extensionAttribute3',
    translateKey: 'extension-attribute'
  },
  {
    example: 'extension-attribute',
    index: 4,
    isMapped: false,
    key: 'extensionAttribute4',
    translateKey: 'extension-attribute'
  },
  {
    example: 'extension-attribute',
    index: 5,
    isMapped: false,
    key: 'extensionAttribute5',
    translateKey: 'extension-attribute'
  },
  {
    example: 'extension-attribute',
    index: 6,
    isMapped: false,
    key: 'extensionAttribute6',
    translateKey: 'extension-attribute'
  },
  {
    example: 'extension-attribute',
    index: 7,
    isMapped: false,
    key: 'extensionAttribute7',
    translateKey: 'extension-attribute'
  },
  {
    example: 'extension-attribute',
    index: 8,
    isMapped: false,
    key: 'extensionAttribute8',
    translateKey: 'extension-attribute'
  },
  {
    example: 'extension-attribute',
    index: 9,
    isMapped: false,
    key: 'extensionAttribute9',
    translateKey: 'extension-attribute'
  },
  {
    example: 'extension-attribute',
    index: 10,
    isMapped: false,
    key: 'extensionAttribute10',
    translateKey: 'extension-attribute'
  },
  {
    example: 'extension-attribute',
    index: 11,
    isMapped: false,
    key: 'extensionAttribute11',
    translateKey: 'extension-attribute'
  },
  {
    example: 'extension-attribute',
    index: 12,
    isMapped: false,
    key: 'extensionAttribute12',
    translateKey: 'extension-attribute'
  },
  {
    example: 'extension-attribute',
    index: 13,
    isMapped: false,
    key: 'extensionAttribute13',
    translateKey: 'extension-attribute'
  },
  {
    example: 'extension-attribute',
    index: 14,
    isMapped: false,
    key: 'extensionAttribute14',
    translateKey: 'extension-attribute'
  },
  {
    example: 'extension-attribute',
    index: 15,
    isMapped: false,
    key: 'extensionAttribute15',
    translateKey: 'extension-attribute'
  }
];

/**
 * List of o365 data mapping fields for adjust mapping on right drop zone
 * Also used in creating dynamic department rules for o365 integration rule create/edit page
 * Merge O365_MAPPING_FIELD_SYNCID object - if need syncId field for mapping
 */
export const O365_MAPPING_FIELDS_DATA: O365MappingFieldsData[] = [
  {
    backendField: 'ma_vorname',
    dataField: null,
    editable: true,
    field: 'ma_vorname',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_vorname'
  },
  {
    backendField: 'ma_nachname',
    dataField: null,
    editable: true,
    field: 'ma_nachname',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_nachname'
  },
  {
    backendField: 'ma_email',
    dataField: null,
    editable: true,
    field: 'ma_email',
    inputPlaceholderKey: 'emptystate_domain',
    required: true,
    translation: 'ma_email'
  },
  {
    backendField: 'ma_foto',
    dataField: null,
    editable: true,
    field: 'ma_foto',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_foto'
  },
  {
    backendField: 'ma_office',
    dataField: null,
    editable: true,
    field: 'ma_office',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'Office'
  },
  {
    backendField: 'ma_position',
    dataField: null,
    editable: true,
    field: 'ma_position',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_position'
  },
  {
    backendField: 'ma_abteilung',
    dataField: null,
    editable: true,
    field: 'ma_abteilung',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_abteilung'
  },
  {
    backendField: 'ma_abteilungs_zuweisung',
    dataField: null,
    editable: true,
    field: 'ma_abteilungs_zuweisung',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_abteilungs_zuweisung'
  },
  {
    backendField: 'ma_pronomen',
    dataField: null,
    editable: true,
    field: 'ma_pronomen',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_pronomen'
  },
  {
    backendField: 'ma_titel',
    dataField: null,
    editable: true,
    field: 'ma_titel',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_titel'
  },
  {
    backendField: 'ma_tel',
    dataField: null,
    editable: true,
    field: 'ma_tel',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_tel'
  },
  {
    backendField: 'ma_fax',
    dataField: null,
    editable: true,
    field: 'ma_fax',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_fax'
  },
  {
    backendField: 'ma_mobil',
    dataField: null,
    editable: true,
    field: 'ma_mobil',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_mobil'
  },
  {
    backendField: 'ma_voip',
    dataField: null,
    editable: true,
    field: 'ma_voip',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_voip'
  },
  {
    backendField: 'ma_firma',
    dataField: null,
    editable: true,
    field: 'ma_firma',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_firma'
  },
  {
    backendField: 'ma_strasse',
    dataField: null,
    editable: true,
    field: 'ma_strasse',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_strasse'
  },
  {
    backendField: 'ma_sector',
    dataField: null,
    editable: true,
    field: 'ma_sector',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'Sector'
  },
  {
    backendField: 'ma_ort',
    dataField: null,
    editable: true,
    field: 'ma_ort',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_ort'
  },
  {
    backendField: 'ma_postal_code',
    dataField: null,
    editable: true,
    field: 'ma_postal_code',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'postal_code'
  },
  {
    backendField: 'ma_land',
    dataField: null,
    editable: true,
    field: 'ma_land',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_land'
  },
  {
    backendField: 'ma_bundesland',
    dataField: null,
    editable: true,
    field: 'ma_bundesland',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_bundesland'
  },
  {
    backendField: 'ma_tel2',
    dataField: null,
    editable: true,
    field: 'ma_tel2',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_tel2'
  },
  {
    backendField: 'ma_grussformel',
    dataField: null,
    editable: true,
    field: 'ma_grussformel',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_grussformel'
  },
  {
    backendField: 'ma_pos_intern',
    dataField: null,
    editable: true,
    field: 'ma_pos_intern',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_pos_intern'
  },
  {
    backendField: 'ma_manual_department',
    dataField: null,
    editable: true,
    field: 'ma_manual_department',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_placeholder_manual_department'
  },
  {
    backendField: 'ma_manual_position',
    dataField: null,
    editable: true,
    field: 'ma_manual_position',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_placeholder_manual_position'
  },
  {
    backendField: 'ma_team',
    dataField: null,
    editable: true,
    field: 'ma_team',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_team'
  },
  {
    backendField: 'ma_disclaimer',
    dataField: null,
    editable: true,
    field: 'ma_disclaimer',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_disclaimer'
  },
  {
    backendField: 'ma_skype',
    dataField: null,
    editable: true,
    field: 'ma_skype',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_skype'
  },
  {
    backendField: 'ma_fb',
    dataField: null,
    editable: true,
    field: 'ma_fb',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_fb'
  },
  {
    backendField: 'ma_googlep',
    dataField: null,
    editable: true,
    field: 'ma_googlep',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_googlep'
  },
  {
    backendField: 'ma_twitter',
    dataField: null,
    editable: true,
    field: 'ma_twitter',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_twitter'
  },
  {
    backendField: 'ma_instagram',
    dataField: null,
    editable: true,
    field: 'ma_instagram',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_instagram'
  },
  {
    backendField: 'ma_linkedin',
    dataField: null,
    editable: true,
    field: 'ma_linkedin',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_linkedin'
  },
  {
    backendField: 'ma_xing',
    dataField: null,
    editable: true,
    field: 'ma_xing',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_xing'
  },
  {
    backendField: 'emp_tiktok',
    dataField: null,
    editable: true,
    field: 'emp_tiktok',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'emp_tiktok'
  },
  {
    backendField: 'ma_website',
    dataField: null,
    editable: true,
    field: 'ma_website',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_website'
  },
  {
    backendField: 'ma_website_2',
    dataField: null,
    editable: true,
    field: 'ma_website_2',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_website_2'
  },
  {
    backendField: 'ma_out_of_office',
    dataField: null,
    editable: true,
    field: 'ma_out_of_office',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_out_of_office'
  },
  {
    backendField: 'ma_add_info_1',
    dataField: null,
    editable: true,
    field: 'ma_add_info_1',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_add_info_1'
  },
  {
    backendField: 'ma_add_info_2',
    dataField: null,
    editable: true,
    field: 'ma_add_info_2',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_add_info_2'
  },
  {
    backendField: 'ma_add_info_3',
    dataField: null,
    editable: true,
    field: 'ma_add_info_3',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_add_info_3'
  },
  {
    backendField: 'ma_add_info_4',
    dataField: null,
    editable: true,
    field: 'ma_add_info_4',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_add_info_4'
  },
  {
    backendField: 'ma_store',
    dataField: null,
    editable: true,
    field: 'ma_store',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'Store'
  },
  {
    backendField: 'ma_ms_teams',
    dataField: null,
    editable: true,
    field: 'ma_ms_teams',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'ma_ms_teams'
  },
  {
    backendField: 'placeholder_branche1_1',
    dataField: null,
    editable: true,
    field: 'placeholder_branche1_1',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'placeholder_branche1_1'
  },
  {
    backendField: 'placeholder_branche1_2',
    dataField: null,
    editable: true,
    field: 'placeholder_branche1_2',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'placeholder_branche1_2'
  },
  {
    backendField: 'placeholder_branche1_3',
    dataField: null,
    editable: true,
    field: 'placeholder_branche1_3',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'placeholder_branche1_3'
  },
  {
    backendField: 'placeholder_branche2_1',
    dataField: null,
    editable: true,
    field: 'placeholder_branche2_1',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'placeholder_branche2_1'
  },
  {
    backendField: 'placeholder_branche2_2',
    dataField: null,
    editable: true,
    field: 'placeholder_branche2_2',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'placeholder_branche2_2'
  },
  {
    backendField: 'placeholder_branche2_3',
    dataField: null,
    editable: true,
    field: 'placeholder_branche2_3',
    inputPlaceholderKey: 'emptystate_domain',
    required: false,
    translation: 'placeholder_branche2_3'
  }
];

/**
 * Object of syncId for data mapping fields
 */
export const O365_MAPPING_FIELD_SYNCID: O365MappingFieldsData = {
  backendField: 'syncId',
  dataField: null,
  editable: true,
  field: 'syncId',
  inputPlaceholderKey: 'emptystate_domain',
  required: true,
  translation: 'syncId'
};

/**
 * Fields values for creating dynamic department rule
 */
export const O365_MAPPING_FIELDS = [O365_MAPPING_FIELD_SYNCID, ...O365_MAPPING_FIELDS_DATA];
